<script setup>
import { computed, watch, ref, onMounted } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import ResponsiblesCard from 'sja.vue.libraries.components.ResponsiblesCard.vue'
import { OPersonsLookup } from 'o365-system-lookups';
import { procAddResponsible } from 'sja.vue.libraries.modules.utilities.js'
import { getOrCreateProcedure } from 'o365-modules';

const props = defineProps({
    hazardRow: Object,
    sjaID: Number,
    itemID: Number,
    readOnly: Boolean,
    participants: Boolean
});

let selPerson = ref(null);
let selRow = ref(null);
let selSJA = ref(null);

let dsActions = getDataObjectById(`dsTasksHazardsActions_${props.sjaID}`)
let dsParticipants = null;

if (props.participants) {
    dsParticipants = getDataObjectById(`dsParticipants${props.itemID ? "_" + props.sjaID : ""}`);
}

const actionData = computed(() => {
    return dsActions.data.filter(item => item.SJATasksHazard_ID == props.hazardRow.ID);
});

watch(selPerson, async (newPerson, prevPerson) => {
    let hasError = false;
    if (newPerson !== null && selSJA.value == props.sjaID) {
        let dataObject = getDataObjectById(`dsTasksHazardsActionsResp_${props.sjaID}`)
        try {
            await procAddResponsible.execute({ SJATasksHazardsAction_ID: selRow.value.ID, Responsible_ID: selPerson.value, });
        } catch (error) {
            hasError = true;
        } finally {
            if (hasError === false) {
                dataObject.load();
                if (props.participants) {
                    dsParticipants.load();
                }
            }
            selPerson.value = null;
            selRow.value = null;
        }
    }
})

const procCreateActionRow = getOrCreateProcedure({ id: "procCreateActionRow", procedureName: "astp_SJA_CreateActionRow" });

async function newRow(row) {
    let dsTasksHazardsActions = null;
    let dsTasksHazardsActionsResp = null;
    try {
        await procCreateActionRow.execute({ ID: row.ID });
        dsTasksHazardsActions = getDataObjectById(`dsTasksHazardsActions_${row.SJA_ID}`);
        dsTasksHazardsActionsResp = getDataObjectById(`dsTasksHazardsActionsResp_${row.SJA_ID}`);
        await dsTasksHazardsActions.load();
        await dsTasksHazardsActionsResp.load();
    } catch (error) {
        console.error("Error loading data:", error);
    }
}

onMounted(() => {
    if (!props.readOnly) {
        checkActions();
    }
})

function checkActions() {
    let allActionTabs = document.querySelectorAll(`.action-tab-${props.hazardRow.ID}`);
    if (allActionTabs.length > 0) {
        allActionTabs.forEach((tab) => {
            tab.innerHTML = $t('New Actions') + `<i type="button" class="new-action-button-${props.hazardRow.ID} ms-2 bi bi-plus-circle"></i>`;
        })
        const icon = document.querySelector(`.new-action-button-${props.hazardRow.ID}`);
        icon.addEventListener("click", function () {
            newRow(props.hazardRow);
        });
        icon.addEventListener("mouseover", function () {
            const title = $t('Add a new action.');
            icon.setAttribute('title', title);
        });
    }
}

</script> 

<template>
    <div class="card">
        <div class="fw-semibold m-1 row gx-2">
            <div class="col-3 text-truncate">
                {{ $t('Action Name') }}
            </div>
            <div class="col text-truncate">
                {{ $t('Comment') }}
            </div>
            <div class="col-4 text-truncate" :title="$t('Those responsible for performing actions.')">
                {{ $t('Responsible') }}
            </div>
            <div class="col-auto">
                <i v-if="!props.readOnly" class="opacity-0 bi bi-x-lg"></i>
            </div>
        </div>
        <div v-for="(action, index) in actionData" class="d-inline">
            <div class="m-1 row gx-2">
                <div class="col-3">
                    <OContentEditable class="w-100 h-100 p-1 text-break"
                        :class="{ 'inner-table-editable': !props.readOnly }" :isEditable="!props.readOnly"
                        v-model="action.Name" @blur="dsActions.save()" />
                </div>
                <div class="col">
                    <OContentEditable class="w-100 h-100 p-1 text-break"
                        :class="{ 'inner-table-editable': !props.readOnly }" :isEditable="!props.readOnly"
                        v-model="action.Comment" @blur="dsActions.save()" />
                </div>
                <div class="col-4">
                    <ResponsiblesCard :actionRow="action" :sjaID="props.sjaID" :readOnly="props.readOnly" />
                    <div v-if="!props.readOnly" class="text-center">
                        <OPersonsLookup class="form-select"
                            :bind="sel => { selPerson = sel.ID; selRow = action; selSJA = props.sjaID }">
                            <template #person>
                                <i :title="$t('Add a person as responsible for the action.')" type="button"
                                    class="px-3 fs-5 text-black bi bi-person-plus-fill">
                                </i>
                            </template>
                        </OPersonsLookup>
                    </div>
                </div>
                <div class="text-center col-auto">
                    <OActionDelete confirm v-if="!props.readOnly" :dataObject="dsActions" :row="action"
                        class="btn btn-sm btn-link p-0" :title="$t('Delete action.')" style="-bs-btn-line-height: 1;">
                        <i class="text-black bi bi-x-lg"></i>
                    </OActionDelete>
                </div>
            </div>

            <hr class="mb-1 mt-2" v-if="props.readOnly && index != actionData.length - 1">
            <span v-else class="d-flex mt-2"></span>
        </div>
    </div>
</template>

<style scoped>
.inner-table-editable {
    border: 1.5px solid var(--o365-grid-border) !important;
    border-radius: var(--bs-border-radius) !important;
}

.inner-table-editable:focus,
.inner-table-editable:hover {
    outline: 0;
    border: 1.5px solid rgba(70, 130, 180, .25) !important;
    box-shadow: 0px 0px 0px 2px rgba(70, 130, 180, .25) !important;
}
</style>
     